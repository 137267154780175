<template>
  <div class="camera-overlay">
    <audio ref="audioRef" src="../assets/pop.mp3">
      <track kind="captions"/>
    </audio>
    <b-button
        @click="closeCameraOverlay"
        class="d-flex align-items-center mt-0 px-0"
        style="background-color:transparent; color: black; border: none; width: 150px"
      >
        <b-icon class="h3 my-0 mx-1" icon="arrow-left" />
        <span class="ml-1"> Regresar </span>
    </b-button>
    <qrcode-stream
      :key="qrKey"
      @decode="handleQRCode"
      @init="handleQRCodeInit"
      :track="paintOutline"
      ref="qrcodeStream"
      class="qrcode-stream"
    ></qrcode-stream>

    <div class="table-container pt-1">
      <h2 style="color: #3B7EB3;">{{ this.accion_name }}</h2>
      <table>
        <thead>
          <tr>
            <th>Archivo</th>
            <th>Dependencia</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in fileList" :key="file.id">
            <td> {{ file.name }} </td>
            <td> {{ file.provider_name }} </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal" v-if="showModal">
      <!-- Contenido del modal -->
      <div class="modal-content">
        <h2>{{ accion_name }}</h2>
        <div v-if="deliverToOffice">
          <p>Archivo: {{ file.name }}</p>
          <p>Dependencia: {{ file.provider_name }}</p>
        </div>
        <button @click="continueAction(true)">Continuar</button>
        <button @click="continueAction(false)">Cancelar</button>
      </div>
    </div>

    <div class="modal" v-if="showCommentModal">
      <div class="modal-content">
        <h2>
          <span v-if="!refuse">¿Todo bien con el tramite?</span>
          <span v-if="refuse">Escribe en breve porque se rechazo el tramite</span>
        </h2>
        <div v-if="!refuse">
          <p>Archivo: {{ file.name }}</p>
          <p>Dependencia: {{ file.provider_name }}</p>
        </div>
        <div v-if="refuse">
          <b-form-textarea
            id="textarea"
            v-model="auditComment"
            placeholder="Escribe el motivo del rechazo"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <button class="btn btn-success" @click="rejectFile(false)" v-if="!refuse">Si</button>
        <button class="btn btn-danger" @click="rejectFile(true)" v-if="!refuse">No</button>
        <button class="btn btn-danger" @click="rejectFile(true)" v-if="refuse">
          Enviar tramite rechazado
        </button>
        <button class="btn btn-success" @click="closeCommentModal()" v-if="refuse">
          Cancelar y volver a comenzar
        </button>
      </div>
    </div>
    <div v-if="isDevelopment" class="d-flex justify-content-center">
      <b-button
        @click="openFileDialog"
        class="d-flex align-items-center mt-4 px-4"
        style="background-color:transparent; color: black; border: 1px solid #3B7EB3; width: 200px"
        aria-label="Cargar Archivo" >
        <b-icon class="h3 my-0 mx-1" icon="upload"/>
        <span class="ml-1"> Cargar Archivo</span>
      </b-button>
      <input type="file"
          ref="fileInput"
          style="display: none;"
          @change="handleFileUpload"
          accept=".txt"
          aria-labelledby="fileInputLabel"/>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Vue from 'vue';
import axios from '../utils/axios';

axios.interceptors.request.use((config) => {
  const appState = localStorage.getItem('appState');
  if (appState) {
    const authToken = `${JSON.parse(appState).authToken}`;
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `${authToken}`;
  }
  return config;
});

export default {
  components: {
    QrcodeStream,
  },
  props: {
    qrEvent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      qrKey: Date.now(),
      isQRCodeInitiated: false,
      file: {
        id: '',
        name: '',
        provider_name: '',
      },
      accion_name: '',
      showModal: false,
      showCommentModal: false,
      auditComment: '',
      refuse: false,
      deliverToOffice: false,
      fileList: [],
      url: '',
      currentEvent: '',
    };
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
  },
  mounted() {
    this.isQRCodeInitiated = true;
  },
  methods: {
    sendToast(text, type) {
      this.$toast(text, {
        position: 'bottom-center',
        timeout: 3689,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: true,
        rtl: false,
        type,
      });
    },
    handleQRCodeInit() {
      Vue.use(Toast, {
        transition: 'Vue-Toastification__bounce',
        maxToasts: 20,
        newestOnTop: true,
      });

      this.currentEvent = this.qrEvent;
      this.auditComment = this.qrEvent;

      switch (this.currentEvent) {
        case 'release_from_office':
          this.accion_name = 'Lo que me estoy llevando de la notaria.';
          break;
        case 'deliver_to_dependency':
          this.accion_name = 'Lo que estoy dejando en la dependencia.';
          break;
        case 'pick_from_dependency':
          this.accion_name = 'Lo que me estoy llevando de la dependencia.';
          break;
        case 'deliver_to_office':
          this.accion_name = 'Lo que estoy regresando a la notaria.';
          break;
        default:
          break;
      }
    },
    handleQRCode(qrUrl) {
      this.url = `${qrUrl}/change_requirement`;
      const caseFileId = this.url.split('/')[4];
      const getUrl = `${axios.defaults.baseURL}/case_file_requirements/${caseFileId}`;

      const handleResponse = (response) => {
        this.file.name = response.data.case_file_requirement.name;
        this.file.provider_name = response.data.case_file_requirement.provider_name;
        this.file.id = response.data.case_file_requirement.id;
        this.deliverToOffice = true;

        if (!this.fileList.includes(this.file)) {
          if (this.currentEvent === 'deliver_to_dependency') {
            this.showModal = true;
          } else if (this.currentEvent === 'pick_from_dependency') {
            this.showCommentModal = true;
          }
        }
      };

      const fetchCaseFileRequirement = () => {
        axios.get(getUrl)
          .then(handleResponse)
          .catch((error) => {
            this.handleError(error);
          });
      };

      const handleEventAction = async (action) => {
        try {
          await action();
          setTimeout(() => {
            this.resetScanner();
          }, 2000);
        } catch (error) {
          console.error('Error handling action:', error);
        }
      };

      if (this.currentEvent === 'deliver_to_dependency' || this.currentEvent === 'pick_from_dependency') {
        handleEventAction(fetchCaseFileRequirement);
      } else {
        handleEventAction(() => this.continueAction(true));
      }
    },
    closeCommentModal() {
      this.showCommentModal = false;
    },
    rejectFile(reject) {
      if (reject) {
        this.currentEvent = 'refuse';
        this.refuse = true;
        if (this.auditComment === this.qrEvent) {
          this.auditComment = '';
        }
      }

      if (this.auditComment !== '') {
        this.continueAction(true);
      } else if (reject) {
        this.sendToast('Asegurate de incluir un comentario antes de enviar el rechazo', 'warning');
      }
    },
    continueAction(canContinue) {
      if (canContinue) {
        axios.post(
          this.url,
          {
            case_file_requirement: {
              dependency_id: '',
              dependency_type: '',
              eta: '',
            },
            comment: this.auditComment,
            event: this.currentEvent,
          },
        )
          .then((response) => {
            if (!this.refuse) {
              this.fileList.push({
                name: response.data.case_file_requirement.name,
                provider_name: response.data.case_file_requirement.provider_name,
                id: response.data.case_file_requirement.id,
              });

              this.$refs.audioRef.play();
            } else {
              this.sendToast('El archivo ha sido rechazado', 'warning');
            }
          })
          .then(() => {
            // Hacer desplazamiento automático hacia abajo
            this.$nextTick(() => {
              const tableContainer = this.$el.querySelector('.table-container');
              tableContainer.scrollTop = tableContainer.scrollHeight;
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      }

      this.showModal = false;
      this.showCommentModal = false;
    },
    closeCameraOverlay() {
      this.$emit('closeCameraOverlay');
    },

    handleError(error) {
      if (error.response.status === 403) {
        this.sendToast('No cuentas con autorización para hacer esto', 'error');
      } else if (error.response.status === 404) {
        this.sendToast('No es posible realizar la acción', 'error');
      } else {
        this.sendToast('Error al leer el código QR', 'error');
      }
      this.resetState();
    },
    resetState() {
      this.showModal = false;
      this.showCommentModal = false;
      this.deliverToOffice = false;
      this.refuse = false;
      this.url = '';
      this.currentEvent = this.qrEvent;
      this.auditComment = this.qrEvent;
    },
    resetScanner() {
      this.qrKey = Date.now();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          this.handleQRCode(content.trim());
        };
        reader.readAsText(file);
      }
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    paintOutline(detectedCodes, ctx) {
      detectedCodes.forEach((detectedCode) => {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        // eslint-disable-next-line no-param-reassign
        ctx.strokeStyle = 'red';
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        otherPoints.forEach((point) => {
          ctx.lineTo(point.x, point.y);
        });
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      });
    },
  },
};
</script>

<style scoped>
.camera-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 98%;
  height: 98%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.qrcode-stream-wrapper {
  max-height: 50vh; /* 50% of the viewport height */
  width: 100%; /* Ensure it takes the full width */
}

@media (max-width: 768px) {
  /* Additional styles for smaller screens if needed */
  .qrcode-stream-wrapper {
    max-height: 50vh;
  }
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3B7EB3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container {
  height: 100%; /* Establece la altura deseada */
  width: 100%;
  overflow: auto; /* Crea la lista desplazable */
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: center; /* Centra el contenido horizontalmente en las celdas */
  padding: 8px;
  border: 1px solid white;
  color: white;
}

thead {
  position: sticky;
  top: 0;
  background-color: #3B7EB3;
}

tbody tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}

.modal-content {
  background-color: white;
  padding: 20px;
  color: black;
}

tr {
  text-align: center; /* Centra el contenido horizontalmente en las celdas */
  padding: 8px;
  border: 1px solid white;
  color: black !important;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

</style>
